import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
import Cookies from '@/cookies/cookies'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL, commented out because of this # https://github.com/vuejs/vue-cli/issues/2380
  base: '/', // we live at: https://game.owiwi.co.uk,
  routes: [
    { path: '*', redirect: '/login' }, // could be 404 error page
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      meta: { unauthorized: true },
      beforeEnter: async(to, from, next) => {
        if (to.query.token) {
          Cookies.set('id_token', to.query.token)

          next({
            path: '/login'
          })
        }

        next()
      },
      // route level code-splitting
      // this generates a separate chunk (login.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ '@/views/Login')
    },
    {
      path: '/game',
      name: 'game',
      meta: { authorized: true },
      component: () => import(/* webpackChunkName: "game-canvas" */ '@/views/GameContainer'),
      children: [
        {
          path: 'video',
          name: 'video',
          component: () => import(/* webpackChunkName: "video" */ '@/views/VideoSequence')
        },
        {
          path: 'book',
          name: 'book',
          component: () => import(/* webpackChunkName: "book" */ '@/views/Book')
        },
        {
          path: 'world_map',
          name: 'world_map',
          component: () => import(/* webpackChunkName: "world-map" */ '@/views/WorldMap')
        },
        {
          path: 'avatar_selection',
          name: 'avatar_selection',
          component: () => import(/* webpackChunkName: "avatar-selection" */ '@/views/AvatarSelection')
        },
        {
          path: 'question',
          name: 'question',
          component: () => import(/* webpackChunkName: "game-question" */ '@/views/GameQuestionContainer')
        },
        {
          path: 'end',
          name: 'end',
          component: () => import(/* webpackChunkName: "game-question" */ '@/views/EndScreen')
        },
        {
          path: 'transition',
          name: 'transition',
          component: () => import(/* webpackChunkName: "game-question" */ '@/views/TransitionScreen')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (
    to.matched.some(record => record.meta.unauthorized) &&
    store.getters.isAuthenticated
  ) {
    next({ path: '/' })
  }
  else if (
    to.matched.some(record => record.meta.authorized) &&
    !store.getters.isAuthenticated
  ) {
    next({ path: '/login' })
  }
  else {
    next()
  }
})

router.beforeResolve((to, from, next) => {
  if (to.name !== 'game') {
    store.commit('setLoading', true)
  }
  next()
})

router.afterEach((to, from) => {
  store.commit('setLoading', false)
})

export default router
