const game = {
  computed: {
    softSkillIdx() {
      return this.$store.getters.softSkillIdx
    }
  },
  methods: {
  }
}

export default game
