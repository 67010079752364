import Vue from 'vue'
import router from '@/router/router'
import store from '@/store/store'

// Essential Vue plugins
import i18n from '@/i18n'

// Custom helpers & libraries
import mixins from '@/mixins/mixins'
import game from '@/mixins/game'

import VueAnalytics from 'vue-analytics'
import * as Sentry from '@sentry/vue'

import App from '@/App'

Vue.mixin(mixins)
Vue.mixin(game)

Vue.config.productionTip = false

Vue.prototype.$isProduction = process.env.NODE_ENV === 'production'
Vue.prototype.$isDevelopment = process.env.NODE_ENV === 'development'
Vue.prototype.$isStaging = process.env.NODE_ENV === 'staging'

if (Vue.prototype.$isProduction) {
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_Google_Analytics_ID
  })

  Sentry.init({
    Vue: Vue,
    dsn: 'https://a1b8ee879df94f84bd24aa1790a38fb8@sentry.io/1433688'
  })
}

export default new Vue({
  store,
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
