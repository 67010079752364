export default {
  isAuthenticated: state => {
    return state.user.token !== null
  },

  playerResultId: state => {
    return state.user.playerResultId
  },

  email: state => {
    return state.user.email
  },

  name: state => {
    return state.user.name
  },

  provider: state => {
    return state.user.provider
  },

  isPiraeus: state => {
    return state.user.provider && state.user.provider.id === 4
  },

  availableIslandsTotal: state => {
    return state.user.availableIslandsTotal
  },

  islands: state => {
    return state.user.islands
  },

  screenRatio: state => {
    return state.screenRatio
  },

  langKey: state => {
    return state.user.langKey
  },

  softSkillIdx: state => {
    return state.user.softSkillIdx
  },

  questionIdx: state => {
    return state.user.questionIdx
  },

  question: state => {
    return state.user.question
  },

  avatar: state => {
    return state.user.avatar
  },

  logoutMsg: state => {
    return state.user.logoutMsg
  },

  questionSecondsTaken: state => {
    const msec = Date.now() - state.question.questionStartTime
    return Math.round(msec / 1000)
  },

  optionSecondsTaken: state => {
    const msec = Date.now() - state.question.optionStartTime
    return Math.round(msec / 1000)
  },

  questionAnswered: state => {
    return state.question.answered
  },

  bestOptionId: state => {
    return state.question.bestOptionId
  },

  worstOptionId: state => {
    return state.question.worstOptionId
  },

  gameplay: state => {
    return state.gameplay
  },

  hasVideoIntro: state => {
    return state.gameplay.introVideoSequence && (state.gameplay.introVideoSequence.videoPathMp4 || state.gameplay.introVideoSequence.videoPathOgg)
  },

  hasVideoOutro: state => {
    return state.gameplay.outroVideoSequence && (state.gameplay.outroVideoSequence.videoPathMp4 || state.gameplay.outroVideoSequence.videoPathOgg)
  },

  mutedBgSound: state => {
    return state.user.mutedBgSound
  },

  mutedNarration: state => {
    return state.user.mutedNarration
  },

  fontSize: state => {
    return state.user.fontSize
  },

  loading: state => {
    return state.loading
  },

  narrationPathAac: state => {
    return state.narration.pathAac
  },

  narrationPathOgg: state => {
    return state.narration.pathOgg
  },

  narrationPlayedOnce: state => {
    return state.narration.playedOnce
  },

  narrationPlaying: state => {
    return state.narration.playing
  },

  narrationFinished: state => {
    return state.narration.finished
  },

  narrationBgSound: state => {
    return state.narration.bgSound
  },

  narrationSound: state => {
    return state.narration.sound
  },

  noSoundGames: state => {
    return state.noSoundGames
  },

  continueSound: state => {
    return state.sounds.continueSound
  },

  bgSound: state => {
    return state.sounds.bgSound
  },

  bestChoiceSound: state => {
    return state.sounds.bestChoiceSound
  },

  leaderboardUsers: state => {
    return state.leaderboard.users
  },

  worstChoiceSound: state => {
    return state.sounds.worstChoiceSound
  },

  sideBtnSound: state => {
    return state.sounds.sideBtnSound
  }
}
