export default {
  namespaced: true,
  state: {
    chapters: ['characters', 'artifacts', 'book', 'map'],
    activePage: 'characters',
    visible: false
  },
  actions: {
    open: ({ commit }) => {
      commit('visible', true)
    },

    close: ({ commit }) => {
      commit('visible', false)
    }
  },
  getters: {
    visible: state => {
      return state.visible
    },

    chapters: state => {
      return state.chapters
    },

    activePage: state => {
      return state.activePage
    }
  },
  mutations: {
    activePage: (state, payload) => {
      state.activePage = payload
    },

    visible: (state, payload) => {
      state.visible = payload
    }
  }
}
