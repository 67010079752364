var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "lang_" + _vm.langKey, attrs: { id: "app" } },
    [
      _c("router-view"),
      _vm.$isProduction
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasResolutonOverlay,
                  expression: "hasResolutonOverlay",
                },
              ],
              attrs: { id: "resolutionOverlay" },
            },
            [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(_vm.$t("resolution_notification")),
                },
              }),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "hidden", attrs: { id: "orientationRotateMsg" } },
        [
          _c("p", {
            staticClass: "animated fadeInUp",
            domProps: { innerHTML: _vm._s(_vm.$t("rotate_notification")) },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }