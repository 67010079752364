import axios from 'axios'
import VueApp from '@/main.js'
import Cookies from '@/cookies/cookies'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API
})

instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.headers.get.accepts = 'application/json'

const parseError = error => {
  let errorText = ''

  // The server response was received with a status code that falls out of the range of 2xx
  // OR
  // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  if (error.response || error.request) {
    const mode = error.response ? 'response' : 'request'

    if (!error[mode].data) {
      errorText = error[mode].statusText
    }
    else if (typeof error[mode].data === 'string') {
      errorText = error[mode].data
    }
    else if (error[mode].data.description) {
      errorText = error[mode].data.description
    }
    else if (error[mode].data.message) {
      errorText = error[mode].data.message
    }
    else if (error[mode].data.status) {
      errorText = error[mode].data.status
    }
  }
  else if (error.message) {
    // Something happened in setting up the request that triggered an error
    errorText = error.message
  }
  else {
    errorText = error
  }

  if (
    errorText === '' ||
    (typeof errorText === 'object' && Object.keys(errorText).length === 0)
  ) {
    return 'Something went wrong!'
  }

  // Capitalize first letter
  errorText = (str => {
    if (typeof str !== 'string') return str
    return str.charAt(0).toUpperCase() + str.slice(1)
  })(errorText)

  return errorText
}

instance.throwError = error => Promise.reject(parseError(error))

instance.handleError = error => parseError(error)

// Add a response interceptor
instance.interceptors.response.use(response => {
  return response
}, error => {
  switch (error.response.status) {
    case 400:
    case 401:
    case 404:
    case 500:
      let msg = ''

      if (error.response.data.message.includes('userHasCompletedTheAssessment') || error.response.data.message === 'assessmentFulfilled') {
        msg = 'already_finished'
      }
      else if (error.response.data.message === 'Bad credentials') {
        msg = 'invalid_credentials'
      }
      else if (error.response.data.message === 'wrongURL') {
        msg = 'You are in the wrong environment. Please contact us!'
      }
      else {
        msg = 'session_expired_msg'
      }

      const message = VueApp.$t(msg)

      if (VueApp.$isProduction) {
        VueApp.$ga.event('Game Exception', 'Error', error.response.data.message)
      }

      delete axios.defaults.headers.common.Authorization
      Cookies.delete('id_token')

      VueApp.$store.commit('setUserToken', null)
      VueApp.$store.commit('setLogoutMsg', message)
      VueApp.$router.replace('/login')
  }
  return Promise.reject(error)
})

export default instance
