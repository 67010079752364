import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import journal from './modules/journal/journal'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      token: null,
      playerResultId: null,
      name: '',
      email: null,
      islands: null,
      langKey: null,
      softSkillIdx: 0,
      questionIdx: -1,
      question: null,
      avatar: null,
      mutedBgSound: false,
      mutedNarration: false,
      fontSize: 'medium',
      logoutMsg: null,
      provider: {}
    },
    screenRatio: null,
    gameplay: null,
    loading: false,
    question: {
      bestOptionId: 0,
      worstOptionId: 0,
      answered: false,
      questionStartTime: 0,
      optionStartTime: 0
    },
    narration: {
      pathAac: null,
      pathOgg: null,
      playedOnce: false,
      playing: false,
      finished: false,
      bgSound: null,
      sound: null
    },
    sounds: {
      continueSound: null,
      bgSound: null,
      bestChoiceSound: null,
      worstChoiceSound: null,
      sideBtnSound: null
    },
    leaderboard: {
      users: {
        good: [],
        bad: [],
        neutral: []
      }
    },
    noSoundGames: [
      'en', 'es', 'br', 'el', 'gr', 'ru', 'us', 'it', 'sl', 'de'
    ]
  },
  getters,
  mutations,
  actions,
  modules: {
    journal
  }
})
