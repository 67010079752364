import axios from '@/axios/axios'
import Cookies from '@/cookies/cookies'

export default {
  login: ({ commit }, payload) => {
    let loginUrl = 'authenticate'

    if (payload.method && payload.method === 'social') {
      loginUrl = 'authenticate/social'
    }

    return axios.post(loginUrl, payload.data)
      .then(res => {
        const idToken = res.data.id_token

        Cookies.set('id_token', idToken, 1)
        axios.defaults.headers.common.Authorization = 'Bearer ' + idToken

        commit('setUserToken', idToken)
      })
  },

  tryAutoLogin: ({ commit, dispatch }) => {
    const idToken = Cookies.get('id_token')

    if (!idToken) {
      return false
    }

    axios.defaults.headers.common.Authorization = 'Bearer ' + idToken

    commit('setUserToken', idToken)

    return true
  },

  getGameState: ({ commit, getters, dispatch }) => {
    if (getters.playerResultId) return true

    return axios.get('game/individual?isGame=true')
      .then(res => {
        commit('setUserGameId', res.data.id)
        commit('setUserName', res.data.userDTO.firstName)
        commit('setUserEmail', res.data.userDTO.email)
        commit('setProvider', res.data.userDTO.provider)
        commit('setAvailableIslandsTotal', res.data.availableIslands)
        return true
      })
  },

  getLanguage: ({ commit, getters, dispatch }) => {
    return axios.get('game/language', {
      params: {
        playerResultId: getters.playerResultId
      }
    })
      .then(res => {
        commit('setGameLang', res.data.langKey)
      })
  },

  setLanguage: ({ commit }, payload) => {
    return axios.post('game/language', payload)
      .then(res => {
        commit('setGameLang', payload.langKey)
      })
  },

  getGameplay: ({ getters }) => {
    return axios.get('game/gameplay', {
      params: {
        playerResultId: getters.playerResultId,
        langKey: getters.langKey
      }
    })
  },

  getSkillsInvited: ({ getters }) => {
    return axios.get('invitation/soft-skills')
  },

  getCurrentQuestion: ({ getters }) => {
    return axios.get('game/currentQuestion', {
      params: {
        playerResultId: getters.playerResultId
      }
    })
  },

  setAvatar: ({ getters }, payload) => {
    return axios.post('game/avatar', {
      playerResultId: getters.playerResultId,
      avatarId: payload.id,
      avatarName: payload.name
    })
  },

  setSound: ({ getters }, muted) => {
    return axios.post('game/sound', {
      playerResultId: getters.playerResultId,
      muted: muted
    })
  },

  setNarration: ({ getters }, muted) => {
    return axios.post('game/narration', {
      playerResultId: getters.playerResultId,
      muted: muted
    })
  },

  setAnswer: ({ getters }, payload) => {
    return axios.post('game/answer', payload)
  },

  setGameComplete: ({ getters }, payload) => {
    window.completingGame = true
    return axios.post('game/complete', payload)
  },

  getLeaderboardUsers: ({ getters, commit }, payload) => {
    return axios({
      method: 'GET',
      url: `leaderboards/users/${payload}.json`,
      baseURL: process.env.VUE_APP_BASE_URL
    })
    .then(res => {
      commit('setLeaderboardUsers', {type: payload, data: res.data})
    })
  }
}
