const mixins = {
  computed: {
    narrationBgSound() {
      return this.$store.getters.narrationBgSound
    },
    narrationSound() {
      return this.$store.getters.narrationSound
    },
    bgSound() {
      return this.$store.getters.bgSound
    },
    continueSound() {
      return this.$store.getters.continueSound
    },
    bestChoiceSound() {
      return this.$store.getters.bestChoiceSound
    },
    worstChoiceSound() {
      return this.$store.getters.worstChoiceSound
    },
    sideBtnSound() {
      return this.$store.getters.sideBtnSound
    },
    narrationPathAac() {
      return this.$store.getters.narrationPathAac
    },
    narrationPathOgg() {
      return this.$store.getters.narrationPathOgg
    },
    soundPathAac() {
      return this.$store.getters.question.soundPathAac
    },
    soundPathOgg() {
      return this.$store.getters.question.soundPathOgg
    },
    questionAnswered() {
      return this.$store.getters.questionAnswered
    },

    imgSize() {
      if (window.innerWidth <= 480) return 'MobilePath'
      else if (window.innerWidth <= 1024) return 'TabletPath'
      return 'DesktopPath'
    }
  },
  methods: {
    propExists(obj, path) {
      return !!path.split('.').reduce((obj, prop) => {
        return obj && obj[prop] ? obj[prop] : undefined
      }, obj)
    },

    varExists(vrbl) {
      return (typeof vrbl !== 'undefined' && vrbl != null)
    },

    getHowlBtnSound(fileName) {
      return new Howl({
        src: ['./audio/' + fileName + '.m4a', './audio/' + fileName + '.ogg'],
        loop: false,
        html5: true,
        volume: 0.1,
        buffer: true,
        mute: this.$store.getters.mutedBgSound
      })
    },

    launchIntoFullscreen() {
      const element = document.documentElement

      if (element.requestFullscreen) element.requestFullscreen()
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen()
      else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen()
      else if (element.msRequestFullscreen) element.msRequestFullscreen()
    },

    exitFromFullscreen() {
      if (document.exitFullscreen) document.exitFullscreen()
      else if (document.webkitExitFullscreen) document.webkitExitFullscreen()
      else if (document.mozCancelFullScreen) document.mozCancelFullScreen()
      else if (document.msExitFullscreen) document.msExitFullscreen()
    },

    startBtnLoading(btn) {
      btn.setAttribute('disabled', true)
      this.continueSound.play()
      btn.classList.add('expandedLeft')
      btn.innerHTML = btn.innerHTML + '<i class="icon-spin6 spin"></i>'
    },

    endBtnLoading(btn) {
      btn.removeAttribute('disabled')
      btn.classList.remove('expandedLeft')
      btn.children[0].remove()
    },

    addTooltips(container) {
      container.find('.tooltip').addClass('inited')
        .qtip({
          content: { attr: 'data-content' },
          position: {
            my: 'bottom center',
            at: 'top center',
            adjust: {
              method: 'shift'
            },
            viewport: jQuery('#app')
          },
          show: {
            solo: true,
            effect: function() {
              jQuery(this).fadeTo(500, 1)
            }
          },
          hide: {
            effect: function() {
              jQuery(this).fadeOut()
            }
          },
          style: 'qtip-tipsy qtip-shadow'
        })
    },

    sequentialImgPreload(imageArray, urlProp, index) {
      index = index || 0

      if (imageArray && imageArray.length > index) {
        const img = new Image()
        img.onload = () => {
          this.sequentialImgPreload(imageArray, urlProp, ++index)
        }
        img.src = imageArray[index][urlProp]
      }
    },

    processGameplay(gameplay) {
      const avatarID = gameplay.gameplayProfile.avatarId

      Object.entries(gameplay.avatarSelection.avatarList).forEach(([i, avatar]) => {
        if (avatarID > 0 && avatarID !== avatar.id) {
          return true
        }
        avatar.smallImg = avatar['small' + this.imgSize]
        avatar.largeImg = avatar['big' + this.imgSize]
      })

      return gameplay
    },

    startNarration(resetSounds) {
      if (resetSounds) {
        this.resetNarrationSound()
      }

      this.$store.commit('setNarrationPlaying', true)

      if (this.varExists(this.question) && this.varExists(this.soundPathAac)) {
        const narrationBgSrc = []
        narrationBgSrc.push(this.soundPathAac)
        if (this.varExists(this.soundPathOgg)) {
          narrationBgSrc.push(this.soundPathOgg)
        }

        if (narrationBgSrc.length > 0) {
          const narrationBgSound = new Howl({
            src: narrationBgSrc,
            loop: true,
            html5: true,
            volume: 0.1,
            buffer: true,
            mute: this.$store.getters.mutedBgSound
          })

          // const langKey = this.$store.getters.gameplay.langKey

          // const noSoundGames = this.$store.getters.noSoundGames

          // if (!noSoundGames.includes(langKey)) {
          //   narrationBgSound.play()
          // }

          narrationBgSound.play()

          this.$store.commit('setNarrationBgSound', narrationBgSound)
        }
      }

      const narrationSrc = []
      if (this.varExists(this.narrationPathAac)) {
        narrationSrc.push(this.narrationPathAac)
      }
      if (this.varExists(this.narrationPathOgg)) {
        narrationSrc.push(this.narrationPathOgg)
      }

      if (narrationSrc.length > 0) {
        const narrationSound = new Howl({
          src: narrationSrc,
          loop: false,
          volume: 1,
          html5: true,
          buffer: true,
          mute: this.$store.getters.mutedNarration,
          onplay: () => {
            if (this.narrationBgSound) {
              this.narrationBgSound.volume(0.1)
            }
          },
          onend: () => {
            this.endNarration()
          }
        })

        const langKey = this.$store.getters.gameplay.langKey

        const noSoundGames = this.$store.getters.noSoundGames

        if (!noSoundGames.includes(langKey)) {
          narrationSound.play()
        }

        this.$store.commit('setNarrationSound', narrationSound)
      }
    },

    skipNarration() {
      this.continueSound.play()

      if (this.narrationSound) {
        this.narrationSound.stop()
      }

      this.endNarration()
      this.sendInteractionToGA('skipped_narration', this.$route.name)
    },

    replayNarration() {
      this.continueSound.play()
      this.$store.commit('setNarrationFinished', false)
      this.startNarration(false)
    },

    endNarration() {
      this.$store.commit('setNarrationPlaying', false)
      this.$store.commit('setNarrationPlayedOnce', true)

      jQuery('.text_container:visible').mCustomScrollbar('stop')

      if (this.$route.name === 'question') {
        this.endQuestionNarration()
      }
      else if (this.$route.name === 'book') {
        this.endBookNarration()
      }
      this.addTooltips(jQuery('#app'))
    },

    endBookNarration() {
      this.$store.commit('setNarrationFinished', true)
    },

    endQuestionNarration() {
      this.increaseNarrationBgVolume()
      if (this.questionAnswered) {
        this.$store.commit('setNarrationFinished', true)
      }
      else {
        this.$store.commit('setOptionStartTime', Date.now())
      }
    },

    resetNarrationSound() {
      if (this.narrationSound) {
        this.narrationSound.unload()
        this.$store.commit('setNarrationSound', null)
      }

      if (this.narrationBgSound) {
        this.narrationBgSound.unload()
        this.$store.commit('setNarrationBgSound', null)
      }
    },

    increaseNarrationBgVolume() {
      if (this.narrationBgSound) {
        this.narrationBgSound.volume(0.4)
      }
    },

    sendGameProgressToGA(action, label, value) {
      if (this.$isProduction) {
        this.$ga.event('Game Progress', action, label, value)
      }
    },

    sendInteractionToGA(action, label) {
      if (this.$isProduction) {
        this.$ga.event('Game Interaction', action, label)
      }
    }
  }
}

export default mixins
