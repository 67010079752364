export default {
  setUserToken: (state, payload) => {
    state.user.token = payload
  },

  setUserGameId: (state, payload) => {
    state.user.playerResultId = payload
  },

  setUserName: (state, payload) => {
    state.user.name = payload
  },

  setUserEmail: (state, payload) => {
    state.user.email = payload
  },

  setProvider: (state, payload) => {
    state.user.provider = payload
  },

  setAvailableIslandsTotal: (state, payload) => {
    state.user.availableIslandsTotal = payload
  },

  setIslands: (state, payload) => {
    state.user.islands = payload
  },

  setGameLang: (state, payload) => {
    state.user.langKey = payload
    document.documentElement.setAttribute('lang', payload)
  },

  setLeaderboardUsers: (state, payload) => {
    if (payload.type == 'good') {
      state.leaderboard.users.good = payload.data
    } else if (payload.type == 'bad') {
      state.leaderboard.users.bad = payload.data
    } else {
      state.leaderboard.users.neutral = payload.data
    }
  },

  screenRatio: (state, payload) => {
    state.screenRatio = payload
  },

  setSoftSkillIdx: (state, payload) => {
    state.user.softSkillIdx = payload
  },

  setQuestionIdx: (state, payload) => {
    state.user.questionIdx = payload
  },

  setCurrentQuestion: (state, payload) => {
    state.user.question = payload
  },

  setGameAvatar: (state, payload) => {
    state.user.avatar = {
      id: payload.id,
      name: payload.defaultName,
      img: payload.largeImg
    }
  },

  setLogoutMsg: (state, payload) => {
    state.user.logoutMsg = payload
  },

  setQuestionStartTime: (state, payload) => {
    state.question.questionStartTime = payload
  },

  setOptionStartTime: (state, payload) => {
    state.question.optionStartTime = payload
  },

  setQuestionAnswered: (state, payload) => {
    state.question.answered = payload
  },

  setBestOptionId: (state, payload) => {
    state.question.bestOptionId = payload
  },

  setWorstOptionId: (state, payload) => {
    state.question.worstOptionId = payload
  },

  setGameData: (state, payload) => {
    state.gameplay = payload
  },

  setUserData: (state, payload) => {
    state.user.mutedNarration = payload.mutedNarration
    state.user.mutedBgSound = payload.mutedSound
  },

  setUserMutedBgSound: (state, payload) => {
    state.user.mutedBgSound = payload
  },

  setUserMutedNarration: (state, payload) => {
    state.user.mutedNarration = payload
  },

  setUserFontSize: (state, payload) => {
    state.user.fontSize = payload
  },

  setLoading: (state, payload) => {
    state.loading = payload
  },

  setNarrationPathAac: (state, payload) => {
    state.narration.pathAac = payload
  },

  setNarrationPathOgg: (state, payload) => {
    state.narration.pathOgg = payload
  },

  setNarrationPlayedOnce: (state, payload) => {
    state.narration.playedOnce = payload
  },

  setNarrationPlaying: (state, payload) => {
    state.narration.playing = payload
  },

  setNarrationFinished: (state, payload) => {
    state.narration.finished = payload
  },

  setNarrationBgSound: (state, payload) => {
    state.narration.bgSound = payload
  },

  setNarrationSound: (state, payload) => {
    state.narration.sound = payload
  },

  setContinueSound: (state, payload) => {
    state.sounds.continueSound = payload
  },

  setBgSound: (state, payload) => {
    state.sounds.bgSound = payload
  },

  setBestChoiceSound: (state, payload) => {
    state.sounds.bestChoiceSound = payload
  },

  setWorstChoiceSound: (state, payload) => {
    state.sounds.worstChoiceSound = payload
  },

  setSideBtnSound: (state, payload) => {
    state.sounds.sideBtnSound = payload
  }
}
